import React from 'react'

function EducationDetails(props) {
  // console.log(props.data.educationDetails)
  return (
    <>
      <div className='  ' style={{ width: '100%' }}>
        <h1 className=' text-[1.7rem] mt-[2rem]' id='edu'>Educational Details</h1>
        {
          props.data.educationDetails?.map((p, i) => {
            return (
              <>
                <div className='flex  items-center justify-between mt-7' style={{ width: '100%' }}>
                  <div className='edu1'>{p.qualification} from {p.institute}
                  </div>
                  <div className='edu2'>{p.passingYear}</div>
                </div>
                <hr className='facultyHr' />
              </>
            )
          }
          )
        }
      </div>
    </>
  )
}

export default EducationDetails
