import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../components/Breadcrumbs'
import Navbar from '../../components/Navbar'
import Footer from '../../Footer'
import Topbar from '../landing/Topbar'
import mscStudent from '../../assests/mscStudent.json'
import axios from 'axios'
import Spinner from '../facultydashboard/formGroup/Spinner'
import { Viewer ,Worker , SpecialZoomLevel} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
function ResearchScholar() {
    const [datas, setData] = React.useState({})
    const [loader, setLoader] = useState(true)
    const [stdatapdf, setStdatapdf] = useState([]);
    // console.log(datas)
    const siteData = () => {
        axios.get('/api/v1/api/site/data')
            .then(function (response) {
                // handle success
                // console.log(response);
                setData(response.data[0])
                setLoader(false)
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }
    const studentpdf = () => {
        axios
            .get("/api/v1/data/ResearchSocholar")
            .then(function (response) {
                // handle success
                //console.log(response.data);
                setStdatapdf(response.data.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    React.useEffect(() => {
        siteData()
    }, [])
    const [stdata, setStdata] = useState([]);

    const student = () => {
        axios
            .get("/api/v1/research/get-all-research")
            .then(function (response) {
                // handle success
                //console.log(response.data);
                setStdata(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    };
    useEffect(() => {
        student();
    }, [])
    useEffect(() => {
        studentpdf();
    }, [])
    // console.log(mscStudent)
    return (

        <div>
            {
                loader && <Spinner />
            }
            <Topbar />
            <Navbar datas={datas} />
            <Breadcrumbs pagelink="#" sublink='#' page={"Research Scholar"} />
            <div className='  md:mx-16 my-4 md:grid '>
                <div className=''>
                {stdatapdf ? <>
  <div className='h-screen'>
<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
    <Viewer defaultScale={SpecialZoomLevel.PageFit} fileUrl={`api/v1/student-upload/${stdatapdf.fileName}`} />
    </Worker>
</div></>:<>
                    <div class="overflow-x-auto rounded-lg border border-gray-200">
                        <table class="min-w-full divide-y-2 divide-gray-200 text-sm">
                            <thead>
                                <tr>
                                    <th
                                        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                                    >
                                        Name
                                    </th>
                                    <th
                                        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                                    >
                                        THESIS TITLE

                                    </th>
                                    <th
                                        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                                    >
                                        PHD REGISTRATION YEAR
                                    </th>
                                    <th
                                        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                                    >
                                        PHD REGISTRATION NO
                                    </th>
                                    <th
                                        class="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900"
                                    >
                                        NAME OF THE GUIDE
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200">
                                {
                                    stdata?.map((e) => {
                                        return (



                                            <>
                                                <tr className="bg-white border-b  dark:border-gray-700">

                                                    <td className="px-6 py-4">{e.name}</td>
                                                    <td className="px-6 py-4">{e.thesisTitle}</td>
                                                    <td className="px-6 py-4">{e.phdRegYear}</td>
                                                    <td className="px-6 py-4">{e.phdRegNo}</td>
                                                    <td className="px-6 py-4">{e.guideName}</td>


                                                </tr>
                                            </>

                                        )
                                    })
                                }

                            </tbody>
                        </table>
                    </div>
                </>
                }
                </div>
            </div>
            <Footer datas={datas} />

        </div>
    )
}

export default ResearchScholar