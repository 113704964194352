import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { AiOutlineEdit } from "react-icons/ai";
import Footer from "../../Footer";
import ashim from "../../assests/AshimPhoto.png";
import orcid from "../../assests/orcid_1.png";
import scopus from "../../assests/Scopus.png";
import Topbar from "../landing/Topbar";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../facultydashboard/formGroup/Spinner";
import ProfileCard from "./ProfileCard";
import EducationDetails from "./biosketch/EducationDetails";
import Expertise from "./biosketch/Expertise";
import ProfessionalBackground from "./biosketch/ProfessionalBackground";
import AdministrativePositions from "./biosketch/AdministrativePositions";
import PersonalDetails from "./biosketch/PersonalDetails";
import Publication from "./research/Publication";
import Collab from "./research/Collab";
import ResearchProject from "./research/ResearchProject";
import Honours from "./honour/Honours";
import Membership from "./honour/Membership";
import MasterStudent from "./supervision/MasterStudents";
import Phd from "./supervision/Phd";
import Event from "./misc/Event";
import Misc from "./misc/Misc";
import Visit from "./misc/Visit";


function Profile() {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const id = useParams();
  // console.log(id.id);

  const getFaculty = () => {
    axios
      .get(`/api/v1/faculty/${id.id}`)
      .then(function (response) {
        // handle success
        setData(response.data.faculty);
        setLoader(false);
        // console.log(response);
        // console.log(response.data.userList);
      })
      .catch(function (error) {
        // handle error
        setLoader(false);
        navigate("/error/page/found");
        console.log(error);
      });
  };

  useEffect(() => {
    getFaculty();
  }, []);
  const [datas, setDatas] = React.useState({})
  // console.log(datas)
  const siteData = () => {
    axios.get('/api/v1/api/site/data')
      .then(function (response) {
        // handle success
        // console.log(response);
        setDatas(response.data[0])
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }
  React.useEffect(() => {
    siteData()
  }, [])

  return (
    <div style={{ width: "100%" }}>
      {loader && <Spinner />}
      <Topbar />
      <Navbar datas={datas} />

      {data && (
        <div className="bg-[#f7f7f7]">
          <div className="pt-4 pb-4 bg-[#f7f7f7]">
            <ProfileCard data={data} />
          </div>
          <div className=" md:flex bg-gray-50  shadow-lg m-auto">
            {/* side menu */}
            <div className="   md:w-1/4">
              <nav
                aria-label="Main Nav"
                class="flex flex-col   sticky top-0 bottom-20  space-y-1"
              >
                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                    <span class="ml-3 text-xl font-bold"> BIOSKETCH </span>

                    <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <nav
                    aria-label="Users Nav"
                    class="flex flex-col mt-2 ml-8 space-y-1"
                  >
                    <a
                      href="#exp"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Expertise
                    </a>
                    <a
                      href="#prof"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Professional Background
                    </a>
                    <a
                      href="#admin"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Administrative Positions
                    </a>
                    <a
                      href="#edu"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Educational Details
                    </a>
                    <a
                      href="#personal"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Personal Details
                    </a>
                  </nav>
                </details>
                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                    <span class="ml-3 uppercase text-xl font-bold">Research</span>

                    <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <nav
                    aria-label="Users Nav"
                    class="flex flex-col mt-2 ml-8 space-y-1"
                  >
                    <a
                      href="#publication"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Publications
                    </a>

                    <a
                      href="#research"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Research Projects
                    </a>

                    <a
                      href="#collab"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Collaborations
                    </a>
                  </nav>
                </details>
                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                    <span class="ml-3 text-xl font-bold">
                      HONOURS AND AWARDS
                    </span>

                    <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <nav
                    aria-label="Users Nav"
                    class="flex flex-col mt-2 ml-8 space-y-1"
                  >
                    <a
                      href="#honour"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Honours
                    </a>

                    <a
                      href="#membership"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Memberships
                    </a>
                  </nav>
                </details>

                {/* <a
                  href="#"
                  class="flex  items-center px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                >
                  <span class="ml-3  text-xl font-bold">
                    {" "}
                    TEACHING ENGAGEMENTS
                  </span>
                </a> */}

                <details class="group hidden [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                    <span class="ml-3 uppercase text-xl font-bold">Supervision</span>

                    <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <nav
                    aria-label="Users Nav"
                    class="flex flex-col mt-2 ml-8 space-y-1"
                  >
                    <a
                      href="#master"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Master Students
                    </a>

                    <a
                      href="#phd"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      PhD Students
                    </a>
                  </nav>
                </details>
                <a
                  href="#award"
                  class="flex items-center  px-4 py-2 text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                >
                  <span class="ml-3 uppercase text-xl font-bold"> Study Materials</span>
                </a>

                <details class="group [&_summary::-webkit-details-marker]:hidden">
                  <summary class="flex  items-center px-4 py-2 text-gray-500 rounded-lg group hover:bg-gray-100 hover:text-gray-700">
                    <span class="ml-3 uppercase text-xl font-bold">MISCELLANEOUS</span>

                    <span class="ml-auto transition duration-300 shrink-0 group-open:-rotate-180">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </summary>

                  <nav
                    aria-label="Users Nav"
                    class="flex flex-col mt-2 ml-8 space-y-1"
                  >
                    <a
                      href="#project"
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Events
                    </a>

                    <a
                      href=""
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Visits
                    </a>
                    <a
                      href=""
                      class="block px-4 py-2 text-md font-semibold text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                    >
                      Miscellaneous
                    </a>
                  </nav>
                </details>
              </nav>
            </div>

            <div className="m-4 w-11/12">
              {/* Biosketch */}
              <div className=" " style={{ width: "100%" }}>
                <div id="exp">
                  <Expertise data={data}/>
                </div>
                <div id="prof">
                  <ProfessionalBackground data={data}/>
                </div>
                <div id="admin">
                  <AdministrativePositions data={data} />
                </div>
                <div id="edu">

                <EducationDetails data={data} />
                </div>
                <div id="personal">

                <PersonalDetails data={data} />
                </div>
              </div>
              {/* edu back end */}
              <div></div>
              {/* Research */}
              <div className=" " style={{ width: "100%" }}>
                <div id="publication">

                <Publication  data={data}/>
                </div>
                <div id="research">

                <ResearchProject data={data} />
                </div>
                <div id="collab">

                <Collab data={data} />
                </div>

              </div>
              <div></div>
              {/* Honour */}
              <div className=" " style={{ width: "100%" }}>
                <div id="honour">

                <Honours data={data} />
                </div>
                <div id="membership">

                <Membership data={data}/>
                </div>
              </div>

              <div></div>
              {/*Supervision*/}
              <div className="hidden " style={{ width: "100%" }}>
                <div id="master">

                <MasterStudent />
                </div>
                <div id="phd">

                <Phd />
                </div>
              </div>

              {/* research ends */}
              <div></div>
              <div className=" " style={{ width: "100%" }}>
                <div id="event">

                <Event loader={setLoader} data={data}/>
                </div>
                <div id="visit">

                <Visit loader={setLoader} data={data}/>
                </div>
                <div id="misc">

                <Misc loader={setLoader} data={data}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer datas={datas}/>
    </div>
  );
}

export default Profile;
