import React from "react";
import { MdEmail } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import orcid from "../../assests/orcid_1.png";
import scopus from "../../assests/Scopus.png";
import Topbar from "../landing/Topbar";
import { Link } from "react-router-dom";
function ProfileCard(props) {
  return (
    <div>
      <div className="faculty bg-[#f7f7f7]  gap-8  m-2 sm:m-[4.3rem] items-start">
        <div className="w-[] bg-white rounded-md m-auto">
          <img
            className="w-[13rem] h-[13rem]"
            style={{ borderRadius: "8px" }}
            src={props.data.profilePic}
          />
        </div>
        <div className="  rounded-md bg-[#f7f7f7]">
          <h1 className="flex justify-between font-bold text-black text-[1.2rem]">
            {props.data.name}
            <Link
              to="/faculty-dashboard"
              className="cursor-pointer my-auto  px-4 py-2  font-light text-sm rounded-md   hover:text-red-600 border  border-black hover:border-red-600"
            >
              Edit Profile{" "}
            </Link>{" "}
          </h1>

          <div className="mt-2 text-[0.9rem]">{props.data.designation}</div>
          <div className="flex item-center mt-6 gap-6">
            <MdEmail className="text-red-500 text-[1.8rem] " />
            <div>{props.data.email}</div>
          </div>
          <div className="flex item-center mt-6 gap-6">
            <IoMdCall className="text-red-500 text-[1.8rem] " />
            <div>
              <span className="text-red-500 font-bold">+91</span>{" "}
              {props.data.mobileNo}{" "}
              <span className="text-red-500 font-bold"></span>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-end">
            {props.data.profileLink ? (
              <>
                <span className="m-3 flex-1  inline-block">
                  <Link
                    to={`${props.data.profileLink}`}
                    target="_blank"
                    className="text-sm   flex"
                  >
                    <BsFillPersonFill className="my-auto text-lg" />
                    <span className="my-auto m-2">Profile Link </span>{" "}
                  </Link>
                </span>{" "}
              </>
            ) : null}
            {/* :ETHEREUM 1 */}
            <span className="m-3 inline-block">
              <Link
                to={`https://orcid.org/${props.data.orcidId}`}
                target="_blank"
                className="text-sm   flex"
              >
                <img src={orcid} className="w-1/6  " />{" "}
                <span className="my-auto m-2">Orcid Id</span>{" "}
              </Link>{" "}
              <Link
                to={`https://orcid.org/${props.data.orcidId}`}
                target="_blank"
              >
                <p className="hover:underline hover:text-blue-600">
                  {props.data.orcidId}
                </p>
              </Link>
            </span>
            <span className="m-3 inline-block">
              <Link
                to={`https://www.scopus.com/authid/detail.uri?authorId=${props.data.scopusId}`}
                target="_blank"
                className="text-sm   flex"
              >
                <img src={scopus} className="w-1/6  " />{" "}
                <span className="my-auto m-2">Scopus Id</span>{" "}
              </Link>{" "}
              <Link
                to={`https://www.scopus.com/authid/detail.uri?authorId=${props.data.scopusId}`}
                target="_blank"
              >
                <p className="hover:underline hover:text-blue-600">
                  {props.data.scopusId}{" "}
                </p>{" "}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
